<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1" color="red darken-1">
        Mandatory Documents
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="e1 > 2" step="2" color="red darken-1">
        Technical Documents
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3" color="red darken-1">
        Financial Documents
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 4" step="4" color="red darken-1">
        Submit Application
      </v-stepper-step>
      <v-divider></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <TenderCard />
        <RequiredDocuments />
        <v-btn
          color="primary"
          @click="e1 = 2"
          class="ml-5 text-capitalize white--text"
        >
          Continue <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <TechnicalDocuments />
        <div class="ml-3">
          <v-btn
            color="error"
            @click="e1 = 1"
            class="mb-1 mx-2 text-capitalize white--text"
          >
            <v-icon>mdi-chevron-left</v-icon> Back
          </v-btn>
          <v-btn
            color="primary"
            @click="e1 = 3"
            class="mb-1 mx-5 text-capitalize white--text"
          >
            Continue <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="3">
        <FinancialDocuments />
        <div class="ml-3">
          <v-btn
            color="error white--text"
            @click="e1 = 2"
            class="mb-1 mx-2 text-capitalize white--text"
          >
            <v-icon>mdi-chevron-left</v-icon> Back
          </v-btn>
          <v-btn
            color="primary"
            @click="e1 = 4"
            class="mb-1 mx-2 text-capitalize white--text"
          >
            Continue <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="4">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="12" class="ml-3">
              <TenderTerms />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-checkbox
              color="primary"
                v-model="terms_and_conditions"
                :label="`Accept the terms & conditions`"
                class="ml-3"
                :rules="[rules.required]"
              ></v-checkbox>
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Corfirm with password"
                hint="At least 6 characters"
                counter
                @click:append="show1 = !show1"
                color="grey"
                outlined
                dense
                class="ml-3"
              ></v-text-field>
              <v-btn
                color="error  darken-1 white--text"
                @click="e1 = 3"
                class="mb-1 mx-2 text-capitalize white--text"
              >
                <v-icon>mdi-chevron-left</v-icon> Back
              </v-btn>
              <v-btn
                color="primary"
                @click="submitApplication"
                class="mb-1 mx-2 text-capitalize white--text"
              >
                Submit <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import TechnicalDocuments from "@/components/TechnicalDocuments.vue";
import RequiredDocuments from "@/components/RequiredDocuments.vue";
import TenderTerms from "@/components/TenderTerms.vue";
import TenderCard from "@/components/TenderCard.vue";
import FinancialDocuments from "@/components/FinancialDocuments.vue";
import TenderService from "../../services/tender.service";

export default {
  components: {
    TechnicalDocuments,
    RequiredDocuments,
    TenderTerms,
    TenderCard,
    FinancialDocuments,
  },
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      valid: true,
      e1: 1,
      show1: false,
      password: "",
      terms_and_conditions:false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 6 || "Min 6 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    tender() {
      return this.$store.state.tender.selected;
    },
  },
  methods: {
    submitApplication() {
      this.$refs.form.validate();
      if (this.valid == true) {
        let conf = confirm(
          "Are you sure you want to submit application? you cannot undo this action."
        );
        if (conf) {
          this.loading = true;
          let formData = {};
          formData.password = this.password
          formData.tender_id = this.tender.id
          formData.applicant_id = this.user.id
          return TenderService.submitTenderApplication(formData).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.$store.dispatch(
                  "alert/success",
                  "Tender application submitted!"
                );
            this.$router.replace('/marketplace');
              } else if (response.status == 201) {
                this.$store.dispatch("alert/error", "Invalid password!");
              } else if (response.status == 202) {
                this.$store.dispatch(
                  "alert/error",
                  "Missing all mandatory documents!"
                );
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Internal server error, please check the form and try again!"
                );
              }
            },
            (error) => {
              console.log(error);
              this.$store.dispatch("alert/error", error.response);
            }
          );
        }
      }
    },
  },
};
</script>